<template>
  <v-container
    class="light-blue lighten-5 pb-16 px-0"
    fluid
  >
    <v-row class="justify-center">
      <v-col
        cols="12"
        lg="8"
      >
        <v-card class="white px-auto">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane-check"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-airplane"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                complete-icon="mdi-airplane"
                step="3"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                complete-icon="mdi-airplane"
                step="4"
              >
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
        <v-card
          img="/img/darkgreen_travel_CA.png"
          color="#005F6C"
        >
          <v-card-title class="white--text font-weight-bold text-h5">
            Canadians at home
          </v-card-title>
          <v-card-title class="pa-2 ma-2">
            <v-row class="wrap">
              <v-col
                cols="8"
                class="white--text font-weight-light"
              >
                Select your plan type
                <v-switch
                  v-model="plantype"
                  color="white"
                  inset
                  class="white--text"
                  @click="clickedField(`plantype: ${plantype}`, 87)"
                >
                  <template v-slot:label>
                    <div class="white--text">
                      {{ tripTypeLabel }}
                    </div>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="loading"
      class="mt-12"
    >
      <v-col class="d-flex justify-center">
        <ellipse-progress
          :loading="loading"
          :size="180"
          img-src="src/assets/images/Travel-Icon.png"
          thickness="5"
          empty-thickness="3"
          line-mode="out 5"
          legend="false"
          animation="rs 700 1000"
          font-size="1.5rem"
          color="#00A1B7"
          empty-color-fill="transparent"
          filename="Travel-Icon.png"
        >
        </ellipse-progress>
      </v-col>
    </v-row>
    <div
      v-for="(obj, i) in userQuotesList"
      :key="i"
      class="py-4"
    >
      <h1 class="text-center">
        {{ obj.name }}'s Plans
      </h1>
      <v-row
        v-for="(product, index) in obj.plans"
        :key="index"
        class="justify-center"
      >
        <v-col
          cols="12"
          lg="8"
        >
          <v-card class="white ma-2">
            <v-card-title>
              <v-icon
                small
                class="ma-2"
                color="primary"
                style="font-size: 30px;"
                outline
              >
                {{ getIconforPlan(product.planCode, planProperties[i]) }}
              </v-icon>
              {{ formatCoverageHeader(product) }}
              <v-tooltip
                v-if="getToolTipForPlan(product.planCode, i)"
                top
                max-width="250px"
                color="grey darken-3"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ml-2"
                    color="primary"
                    style="font-size: 30px;"
                    v-bind="attrs"
                    outline
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ getTip(product.planCode, planProperties[i]) }}</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <div class="ma-4 text-h5 blanketgrey--text">
                {{ formatAmount(product.chargedPrice.total) }}
              </div>
            </v-card-title>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                :class="mobileDevice ? 'mx-n7' : 'mr-0'"
                @click="
                  getDialogInfo(product.planCode, planProperties[i]);
                  currentProduct = product;
                  detailsAndCoveragesDialog = true;
                  clickedFieldGlobal(
                    'View details and coverages',
                    -1,
                    'Canadian Travel Survey v1'
                  );
                "
              >
                View details and coverages
              </v-btn>
              <v-spacer></v-spacer>
              <v-tooltip
                v-if="!productActive(product, planProperties[i])"
                top
                color="grey darken-3"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ma-4"
                    color="warning"
                    style="font-size: 30px;"
                    v-bind="attrs"
                    outline
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>This plan cannot be sold based on one of the packages
                  selected</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  businessRules(product, i, planProperties[i]) &&
                    productActive(product, planProperties[i])
                "
                top
                color="grey darken-3"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="ma-4"
                    color="red"
                    style="font-size: 30px;"
                    v-bind="attrs"
                    outline
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>This plan cannot be sold on its own. It is sold with primary packages.</span>
              </v-tooltip>
              <v-btn
                v-if="
                  !businessRules(product, i, planProperties[i]) &&
                    productActive(product, planProperties[i])
                "
                :class="[
                  planSelected(product, i) ? 'green' : 'primary',
                  mobileDevice ? '' : ''
                ]"
                @click="
                  addPlan(product, i, planProperties[i]);
                  checkPlans();
                  clickedFieldGlobal(
                    'choosePlan addPlanBtn',
                    88,
                    'Canadian Travel Survey v1'
                  );
                "
              >
                <v-icon
                  v-if="planSelected(product, i)"
                  left
                >
                  mdi-check-circle
                </v-icon>
                {{ planButtonLabel(product, i) }}

                <v-icon
                  v-if="planSelected(product, i)"
                  right
                >
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="detailsAndCoveragesDialog"
      max-width="800"
    >
      <v-card>
        <v-btn
          text
          color="transparent"
          class="closeDialogBtn"
          @click="
            currentPanel = null;
            detailsAndCoveragesDialog = false;
            clickedFieldGlobal(
              'choosePlan closeDialogBtn',
              89,
              'Canadian Travel Survey v1'
            );
          "
        >
          <v-icon
            color="black"
            large
          >
            mdi-close
          </v-icon>
        </v-btn>
        <v-card-title class="text-h2 mb-12 primary--text px-10">
          {{ currentProduct.planName }}
        </v-card-title>
        <v-card-subtitle class="font-weight-bold">
          Plan Details & Coverage Limits
        </v-card-subtitle>
        <v-card-text
          v-for="(limit, i) in dialogInfo.limits"
          :key="i"
          class="ma-0 pt-0 pb-1"
        >
          {{ limit.text }}
        </v-card-text>
        <v-expansion-panels :value="currentPanel">
          <v-expansion-panel
            v-for="(panel, i) in dialogInfo.panels"
            :key="panel.title"
            @change="handlePanelChange(i)"
          >
            <v-expansion-panel-header
              class="text-h4 font-weight-bold primary--text"
            >
              {{ panel.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-subtitle>{{ panel.subtitle }}</v-card-subtitle>
              <ul>
                <li
                  v-for="(item, j) in panel.list"
                  :key="j"
                >
                  {{ item }}
                </li>
              </ul>
              <div v-if="panel.secondaryInfo">
                <v-card-subtitle>
                  {{
                    panel.secondaryInfo.subtitle
                  }}
                </v-card-subtitle>
                <ul>
                  <li
                    v-for="(item, j) in panel.secondaryInfo.list"
                    :key="j"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="justify-center pb-16">
      <v-col
        cols="12"
        lg="8"
      >
        <v-row class="justify-center">
          <div class="black--text text-h5 ma-2 pa-2">
            Your Purchase Plan Details
          </div>
        </v-row>
        <v-row class="justify-center white">
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="justify-center"
          >
            <v-card elevation="0">
              <v-card-title class="blanketsecondary--text">
                SELECTED PLANS(S)
              </v-card-title>
              <div
                v-for="(obj, i) in userQuotesList"
                :key="i"
                class="py-4"
              >
                <h3 class="pl-4">
                  {{ obj.name }}'s Selected Plans
                </h3>
                <v-card-text
                  v-for="(item, index) of selectedPlans[i]"
                  :key="index"
                  class="primary--text"
                >
                  {{ item.genericName }} <br />
                  {{ formatAmount(item.chargedPrice.total) }}
                </v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="d-flex justify-center blanketsecondary--text"
          >
            <v-divider vertical></v-divider>
            <v-card elevation="0">
              <v-card-title class="blanketsecondary--text">
                TRIP DETAILS
              </v-card-title>
              <v-card-text>
                Start Date: {{ travelDetails.startDate }}
              </v-card-text>
              <v-card-text> End Date: {{ travelDetails.endDate }} </v-card-text>
              <v-card-text> Origin: {{ travelDetails.origin }} </v-card-text>
              <v-card-text>
                Destination: {{ travelDetails.destination }}
                {{ travelDetails.primaryDestination }}
              </v-card-text>
              <v-card-title class="blanketsecondary--text">
                TRAVELER INFORMATION
              </v-card-title>
              <v-card-text>
                Traveler 1, {{ travelDetails.primaryTravellerName }}, Age:
                {{ travelDetails.primaryTravellerAge }}
              </v-card-text>
              <v-card-text v-if="travelDetails.additionalTravelers">
                <span
                  v-for="(item, i) in travelDetails.additionalTravelers"
                  :key="i"
                >
                  Traveler {{ i + 2 }}, {{ item.name }}, Age:
                  {{ item.age }}
                </span>
              </v-card-text>
            </v-card>
            <v-divider vertical></v-divider>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="d-flex justify-center blanketsecondary--text"
          >
            <v-card
              elevation="0"
              color="#DEECF1"
            >
              <v-img src="/img/tripdetailcanada.png"></v-img>
              <v-card-title class="blanketsecondary--text">
                TOTAL PREMIUM
              </v-card-title>
              <v-card-title class="blanketsecondary--text">
                {{ selectedCost }}
              </v-card-title>
              <v-card-subtitle class="mt-2">
                Minumum charges and taxes may apply
              </v-card-subtitle>
              <v-card-actions class="justify-center">
                <v-btn
                  :disabled="disabled"
                  color="primary"
                  @click="
                    submit();
                    clickedFieldGlobal(
                      'choosePlan purchaseYourPlan',
                      90,
                      'Canadian Travel Survey v1'
                    );
                  "
                >
                  Purchase your plan
                </v-btn>
              </v-card-actions>
              <v-row
                v-if="loading"
                class="mt-12"
              >
                <v-col class="d-flex justify-center">
                  <ellipse-progress
                    :loading="loading"
                    :size="180"
                    img-src="src/assets/images/Travel-Icon.png"
                    thickness="5"
                    empty-thickness="3"
                    line-mode="out 5"
                    legend="false"
                    animation="rs 700 1000"
                    font-size="1.5rem"
                    color="#00A1B7"
                    empty-color-fill="transparent"
                    filename="Travel-Icon.png"
                  >
                  </ellipse-progress>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="policyError"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-card-text>
          <div class="text-h2 pa-12">
            Please select at least 1 plan per traveller.
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            text
            x-large
            @click="
              policyError = false;
              clickedFieldGlobal(
                'choosePlan policyErrorCloseBtn',
                91,
                'Canadian Travel Survey v1'
              );
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  let { AgeFromDateString, AgeFromDate } = require('age-calculator')
  var numeral = require('numeral')
  export default {
    data () {
      return {
        carrier: '',
        cost: 0,
        currentMultiQuote: '',
        currentProduct: {},
        currentPanel: null,
        dialogInfo: '',
        plantype: '',
        dialog: '',
        travelProducts: '',
        travelDetails: '',
        singleTripPlans: '',
        currentSingleQuote: '',
        detailsAndCoveragesDialog: false,
        disabled: true,
        plan: '',
        policyError: false,
        price: '',
        selectedPlans: [[], [], [], [], []],
        // code | icon | sold alone | tool tip
        planProperties0: new Map([
          [
            'PL-STI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-SPCK-17',
            {
              icon: 'mdi-account-arrow-right-outline',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SMED-26',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SMED-25',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SBAG-3',
            {
              icon: 'mdi-bag-suitcase',
              solo: false,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SADD-5',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SSRC-3',
            { icon: 'mdi-car', solo: false, showtip: false, active: true }
          ],
          [
            'PL-SMED-1',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-SPCK-2',
            {
              icon: 'mdi-account-convert-outline',
              solo: true,
              showtip: true,
              tip:
                "Everything you'll need in one easy package: Emergency Medical, Trip Cancellation & Trip Interruption, Accidental Death and Dismemberment and Baggage Insurance.",
              active: true
            }
          ],
          [
            'PL-SMED-4',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?",
              active: true
            }
          ],
          [
            'PL-SMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-STCAN-7',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-1',
            {
              icon: 'mdi-cancel',
              solo: false,
              showtip: true,
              tip:
                'The sum insured for trip cancellation corresponds to the cost of all your trips for the year. You can choose a sum insured that equals the combined cost of all your trips or the cost of your most expensive trip. If you need to increase your sum insured later on, you can. This amount is per person for the entire year.',
              active: true
            }
          ],
          [
            'PL-MMED-17',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MMED-18',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MTI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-5',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTI-1',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MADD-1',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: true,
              tip:
                'Insuring you and your loved ones for accidental death or dismemberment.',
              active: true
            }
          ],
          [
            'PL-MADD-4',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-MMED-2',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-MMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ]
        ]),
        planProperties1: new Map([
          [
            'PL-STI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-SPCK-17',
            {
              icon: 'mdi-account-arrow-right-outline',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SMED-26',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SMED-25',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SBAG-3',
            {
              icon: 'mdi-bag-suitcase',
              solo: false,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SADD-5',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SSRC-3',
            { icon: 'mdi-car', solo: false, showtip: false, active: true }
          ],
          [
            'PL-SMED-1',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-SPCK-2',
            {
              icon: 'mdi-account-convert-outline',
              solo: true,
              showtip: true,
              tip:
                "Everything you'll need in one easy package: Emergency Medical, Trip Cancellation & Trip Interruption, Accidental Death and Dismemberment and Baggage Insurance.",
              active: true
            }
          ],
          [
            'PL-SMED-4',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?",
              active: true
            }
          ],
          [
            'PL-SMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-STCAN-7',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-1',
            {
              icon: 'mdi-cancel',
              solo: false,
              showtip: true,
              tip:
                'The sum insured for trip cancellation corresponds to the cost of all your trips for the year. You can choose a sum insured that equals the combined cost of all your trips or the cost of your most expensive trip. If you need to increase your sum insured later on, you can. This amount is per person for the entire year.',
              active: true
            }
          ],
          [
            'PL-MMED-17',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MMED-18',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MTI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-5',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTI-1',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MADD-1',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: true,
              tip:
                'Insuring you and your loved ones for accidental death or dismemberment.',
              active: true
            }
          ],
          [
            'PL-MADD-4',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-MMED-2',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-MMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ]
        ]),
        planProperties2: new Map([
          [
            'PL-STI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-SPCK-17',
            {
              icon: 'mdi-account-arrow-right-outline',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SMED-26',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SMED-25',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SBAG-3',
            {
              icon: 'mdi-bag-suitcase',
              solo: false,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SADD-5',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SSRC-3',
            { icon: 'mdi-car', solo: false, showtip: false, active: true }
          ],
          [
            'PL-SMED-1',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-SPCK-2',
            {
              icon: 'mdi-account-convert-outline',
              solo: true,
              showtip: true,
              tip:
                "Everything you'll need in one easy package: Emergency Medical, Trip Cancellation & Trip Interruption, Accidental Death and Dismemberment and Baggage Insurance.",
              active: true
            }
          ],
          [
            'PL-SMED-4',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?",
              active: true
            }
          ],
          [
            'PL-SMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-STCAN-7',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-1',
            {
              icon: 'mdi-cancel',
              solo: false,
              showtip: true,
              tip:
                'The sum insured for trip cancellation corresponds to the cost of all your trips for the year. You can choose a sum insured that equals the combined cost of all your trips or the cost of your most expensive trip. If you need to increase your sum insured later on, you can. This amount is per person for the entire year.',
              active: true
            }
          ],
          [
            'PL-MMED-17',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MMED-18',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MTI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-5',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTI-1',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MADD-1',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: true,
              tip:
                'Insuring you and your loved ones for accidental death or dismemberment.',
              active: true
            }
          ],
          [
            'PL-MADD-4',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-MMED-2',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-MMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ]
        ]),
        planProperties3: new Map([
          [
            'PL-STI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-SPCK-17',
            {
              icon: 'mdi-account-arrow-right-outline',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SMED-26',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SMED-25',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SBAG-3',
            {
              icon: 'mdi-bag-suitcase',
              solo: false,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SADD-5',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SSRC-3',
            { icon: 'mdi-car', solo: false, showtip: false, active: true }
          ],
          [
            'PL-SMED-1',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-SPCK-2',
            {
              icon: 'mdi-account-convert-outline',
              solo: true,
              showtip: true,
              tip:
                "Everything you'll need in one easy package: Emergency Medical, Trip Cancellation & Trip Interruption, Accidental Death and Dismemberment and Baggage Insurance.",
              active: true
            }
          ],
          [
            'PL-SMED-4',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?",
              active: true
            }
          ],
          [
            'PL-SMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-STCAN-7',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-1',
            {
              icon: 'mdi-cancel',
              solo: false,
              showtip: true,
              tip:
                'The sum insured for trip cancellation corresponds to the cost of all your trips for the year. You can choose a sum insured that equals the combined cost of all your trips or the cost of your most expensive trip. If you need to increase your sum insured later on, you can. This amount is per person for the entire year.',
              active: true
            }
          ],
          [
            'PL-MMED-17',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MMED-18',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MTI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-5',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTI-1',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MADD-1',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: true,
              tip:
                'Insuring you and your loved ones for accidental death or dismemberment.',
              active: true
            }
          ],
          [
            'PL-MADD-4',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-MMED-2',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-MMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ]
        ]),
        planProperties4: new Map([
          [
            'PL-STI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-SPCK-17',
            {
              icon: 'mdi-account-arrow-right-outline',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SMED-26',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SMED-25',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-SBAG-3',
            {
              icon: 'mdi-bag-suitcase',
              solo: false,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SADD-5',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-SSRC-3',
            { icon: 'mdi-car', solo: false, showtip: false, active: true }
          ],
          [
            'PL-SMED-1',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-SPCK-2',
            {
              icon: 'mdi-account-convert-outline',
              solo: true,
              showtip: true,
              tip:
                "Everything you'll need in one easy package: Emergency Medical, Trip Cancellation & Trip Interruption, Accidental Death and Dismemberment and Baggage Insurance.",
              active: true
            }
          ],
          [
            'PL-SMED-4',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?",
              active: true
            }
          ],
          [
            'PL-SMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-STCAN-7',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-1',
            {
              icon: 'mdi-cancel',
              solo: false,
              showtip: true,
              tip:
                'The sum insured for trip cancellation corresponds to the cost of all your trips for the year. You can choose a sum insured that equals the combined cost of all your trips or the cost of your most expensive trip. If you need to increase your sum insured later on, you can. This amount is per person for the entire year.',
              active: true
            }
          ],
          [
            'PL-MMED-17',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MMED-18',
            { icon: 'mdi-medical-bag', solo: true, showtip: true, active: true }
          ],
          [
            'PL-MTI-3',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MTCAN-5',
            { icon: 'mdi-cancel', solo: false, showtip: false, active: true }
          ],
          [
            'PL-MTI-1',
            { icon: 'mdi-airplane', solo: true, showtip: false, active: true }
          ],
          [
            'PL-MADD-1',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: true,
              tip:
                'Insuring you and your loved ones for accidental death or dismemberment.',
              active: true
            }
          ],
          [
            'PL-MADD-4',
            {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: true
            }
          ],
          [
            'PL-MMED-2',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ],
          [
            'PL-MMED-3',
            {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: true
            }
          ]
        ]),
        planProperties: []
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      loading () {
        return this.$store.getters.getloading
      },
      userQuotesList () {
        const supportedProductCodes = [
          'PL-MTI-1',
          'PL-SPCK-2',
          'PL-MTCAN-1',
          'PL-SPCK-17',
          'PL-MMED-2',
          'PL-MMED-3',
          'PL-STI-3',
          'PL-STCAN-7',
          'PL-SMED-1',
          'PL-SMED-4',
          'PL-SMED-3'
        ]
        this.currentMultiQuote = []
        if (this.$store.getters.getCanadianTravelQuote) {
          this.currentMultiQuote = this.$store.getters.getCanadianTravelQuote.filter(
            entry => entry.priceBreakdown.planType === 'MULTI' && supportedProductCodes.includes(entry.priceBreakdown.planCode)
          )
        }
        this.currentSingleQuote = []
        if (this.$store.getters.getCanadianTravelQuote) {
          this.currentSingleQuote = this.$store.getters.getCanadianTravelQuote.filter(
            entry => entry.priceBreakdown.planType === 'SINGLE' && supportedProductCodes.includes(entry.priceBreakdown.planCode)
          )
        }
        let plans = []
        let plansPerPerson = []
        let uniquePersonIds = []
        if (this.plantype) {
          // this is multi if true
          plans = this.currentMultiQuote
          // get a list of person Ids
          // place their name with their age, in the order sent
          uniquePersonIds = [
            ...new Set(plans.map(data => data.priceBreakdown.personId))
          ]
          uniquePersonIds.forEach((personID, index) => {
            // add primary
            let name = ''
            if (index === 0) {
              name = this.travelDetails.primaryTravellerName
            } else {
              name = this.travelDetails.additionalTravelers[index - 1].name
            }
            let plansForPerson = []
            plansForPerson = plans.filter(
              entry => entry.priceBreakdown.personId === personID
            )
            plansPerPerson.push({ name: name, plans: plansForPerson })
          })
        } else {
          // here ben, this is the quotes broken down for each traveller
          plans = this.currentSingleQuote
          uniquePersonIds = [
            ...new Set(plans.map(data => data.priceBreakdown.personId))
          ]
          uniquePersonIds.forEach((personID, index) => {
            // add primary
            let name = ''
            if (index === 0) {
              name = this.travelDetails.primaryTravellerName
            } else {
              name = this.travelDetails.additionalTravelers[index - 1].name
            }
            let plansForPerson = []
            plansForPerson = plans.filter(
              entry => entry.priceBreakdown.personId === personID
            )
            plansPerPerson.push({ name: name, plans: plansForPerson })
          })
        }
        // overlay the names in the same index
        // travelDetails.additionalTravelers
        return plansPerPerson
      },
      userQuotes () {
        let supportedProductCodes = [
          'PL-MTI-1',
          'PL-SPCK-2',
          'PL-MTCAN-1',
          'PL-SPCK-17',
          'PL-MMED-2',
          'PL-MMED-3',
          'PL-STI-3',
          'PL-STCAN-7',
          'PL-SMED-1',
          'PL-SMED-4',
          'PL-SMED-3'
        ]
        this.currentMultiQuote = []
        if (this.$store.getters.getCanadianTravelQuote) {
          this.currentMultiQuote = this.$store.getters.getCanadianTravelQuote
            .filter(entry => entry.priceBreakdown.planType === 'MULTI' && supportedProductCodes.includes(entry.priceBreakdown.planCode))
        }
        this.currentSingleQuote = []
        if (this.$store.getters.getCanadianTravelQuote) {
          this.currentSingleQuote = this.$store.getters.getCanadianTravelQuote.filter(
            entry => entry.priceBreakdown.planType === 'SINGLE' && supportedProductCodes.includes(entry.priceBreakdown.planCode))
        }
        let plans = []
        if (this.plantype) {
          // this is multi if true
          plans = this.currentMultiQuote
        } else {
          plans = this.currentSingleQuote
        }
        return plans
      },
      coverageDetails () {
        return {
          plan: this.plan,
          price: this.cost,
          carrier: this.carrier,
          selectedPlans: this.selectedPlans
        }
      },
      selectedCost () {
        const costs = []
        this.selectedPlans.forEach(plan => {
          if (plan.length > 0) {
            plan.forEach(plan => costs.push(plan.chargedPrice.total))
          }
        })
        if (costs.length > 0) {
          this.cost = costs.reduce((acc, val) => acc + val, 0)
        }
        return numeral(this.cost).format('$0.00')
      },
      tripTypeLabel () {
        let label = 'Single'
        if (this.plantype) {
          label = 'Multi Trip Annual'
        }
        return label
      },
      validForm () {
        let disabled = true
        if (this.selectedPlans[0].length > 0) {
          disabled = false
        }
        return disabled
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      detailsAndCoveragesDialog (val) {
        if (val === false) this.currentPanel = null
      }
    },
    created () {
      // this.travelProducts = this.$store.getters.getCanadianTravelProducts.find(entry => entry.code === 'BUN-FDM-GEN') // only offering canadian resident travel insurance currently
      // single only no multi yet
      // this.singleTripPlans = this.travelProducts.plans.filter(entry => entry.tripType === 'SINGLE')
      this.planProperties = [
        this.planProperties0,
        this.planProperties1,
        this.planProperties2,
        this.planProperties3,
        this.planProperties4
      ]
      this.currentMultiQuote = []
      if (this.$store.getters.getCanadianTravelQuote) {
        this.currentMultiQuote = this.$store.getters.getCanadianTravelQuote.filter(
          entry => entry.planType === 'MULTI'
        )
      }
      this.currentSingleQuote = []
      if (this.$store.getters.getCanadianTravelQuote) {
        this.currentSingleQuote = this.$store.getters.getCanadianTravelQuote.filter(
          entry => entry.planType === 'SINGLE'
        )
      }
      // sort alpha by plan name
      this.currentSingleQuote = this.currentSingleQuote.sort(function (a, b) {
        if (a.planName < b.planName) {
          return -1
        }
        if (a.planName > b.planName) {
          return 1
        }
        return 0
      })
      this.currentMultiQuote = this.currentMultiQuote.sort(function (a, b) {
        if (a.planName < b.planName) {
          return -1
        }
        if (a.planName > b.planName) {
          return 1
        }
        return 0
      })
      this.currentuser = this.$store.getters.getuser
      this.travelDetails = this.$store.getters.getTravelDetails
    },
    methods: {
      getDialogInfo (planCode) {
        this.dialogInfo = this.$store.getters.getCanadianTravelDetailsAndCoverage.find(
          entry => entry.code === planCode
        )
      },
      planButtonLabel (product, index) {
        if (this.selectedPlans[index].includes(product)) {
          return 'Added'
        // its not in the cart
        }
        return 'Add to Cart'
      },
      close () {
        this.dialog = false
      },
      handlePanelChange (index) {
        this.currentPanel = index
      },
      async removePlan () {
        this.loading = true
        await new Promise(resolve => setTimeout(resolve, 3000))
        this.loading = false
      },
      productActive (product, planMap) {
        if (!planMap) return false

        let props = planMap.get(product.planCode)
        if (props.active === true) {
          return true
        }
        return false
      },
      businessRules (product, index, planMap) {
        // true if disable the button
        let disabled = true
        // if our selected products is empty and product is not solo
        // if inclusive is special case
        if (this.selectedPlans[index] && this.selectedPlans[index].length === 0) {
          // check if one plan is all inclusive one
          let soloprops = planMap.get(product.planCode)
          if (soloprops.solo) {
            return false
          } else {
            return true
          }
        } else {
          // selected plans isn't empty
          // selected plans has a solo plan in it
          const INCLUSIVE_PLAN_CODE = 'PL-SPCK-2'
          let userplans = this.selectedPlans[index]?.map(entry => entry.planCode)
          if (userplans?.includes(INCLUSIVE_PLAN_CODE)) {
            // turn off all but trip cancel and baggage
            planMap.set('PL-SADD-5', {
              icon: 'mdi-account-injury',
              solo: true,
              showtip: false,
              active: false
            })
            planMap.set('PL-SMED-1', {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: false
            })
            planMap.set('PL-STI-3', {
              icon: 'mdi-airplane',
              solo: true,
              showtip: false,
              active: false
            })
            planMap.set('PL-SMED-3', {
              icon: 'mdi-medical-bag',
              solo: false,
              showtip: true,
              tip:
                'This plan will cover you for emergency hospital stays or emergency medical treatment.',
              active: false
            })
            planMap.set('PL-SMED-4', {
              icon: 'mdi-medical-bag',
              solo: true,
              showtip: true,
              tip:
                "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?",
              active: false
            })
            planMap.set('PL-SPCK-17', {
              icon: 'mdi-account-arrow-right-outline',
              solo: true,
              showtip: false,
              active: false
            })
            planMap.set('PL-SSRC-3', {
              icon: 'mdi-car',
              solo: false,
              showtip: false,
              active: false
            })
            planMap.set('PL-STCAN-7', {
              icon: 'mdi-cancel',
              solo: false,
              showtip: false,
              active: false,
            })
          }
          return false
        }
      },
      getIconforPlan (planCode) {
        let icon = 'mdi-shopping'
        icon = this.planProperties0.get(planCode).icon
        return icon
      },
      getActive (planCode, planMap) {
        let active = false
        active = planMap.get(planCode).active
        return active
      },
      getToolTipForPlan (planCode, index) {
        let showtip = false
        let map = this.planProperties[index]
        if (map) showtip = map?.get(planCode).showtip
        return showtip
      },
      getTip (planCode, planMap) {
        let tipData
        tipData = planMap.get(planCode).tip
        return tipData
      },
      formatAmount (val) {
        return numeral(val).format('$0.00')
      },
      checkPlans () {
        this.disabled = this.userQuotesList.some((_, i) => this.selectedPlans[i].length === 0)
      },
      addPlan (item, index, planMap) {
        const selectedPlans = this.selectedPlans[index]
        const inclusivePlanCode = 'PL-SPCK-2'

        if (selectedPlans.includes(item)) {
          // Remove the plan from the selected plans
          const itemIndex = selectedPlans.indexOf(item)
          this.clickedField(`item: ${item.planCode} removed from cart`, -1)
          selectedPlans.splice(itemIndex, 1)

          if (selectedPlans.length === 0 && item.planCode === inclusivePlanCode) {
            // Restore other plans if all inclusive plans were removed
            const defaultPlans = [
              { code: 'PL-SADD-5', icon: 'mdi-account-injury', solo: true, showtip: false, active: true },
              { code: 'PL-SMED-1', icon: 'mdi-medical-bag', solo: true, showtip: true, tip: 'This plan will cover you for emergency hospital stays or emergency medical treatment.', active: true },
              { code: 'PL-STI-3', icon: 'mdi-airplane', solo: true, showtip: false, active: true },
              { code: 'PL-STCAN-7', icon: 'mdi-cancel', solo: false, showtip: false, active: true },
              { code: 'PL-SMED-3', icon: 'mdi-medical-bag', solo: true, showtip: true, tip: 'This plan will cover you for emergency hospital stays or emergency medical treatment.', active: true },
              { code: 'PL-SMED-4', icon: 'mdi-medical-bag', solo: true, showtip: true, tip: "With this plan, you'll be covered for up to 48 hours for flight stopovers or layovers in the US and up to 5 days while transiting through the US by vehicle. If a medical emergency occurs outside of these days, you won't be protected. Please also be aware, if you are travelling by cruise ship and the cruise departs from or stops at any ports in the US, you must purchase a Worldwide plan for the full duration of your trip. Not the right plan for you? Why not try Medical Worldwide instead?", active: true },
              { code: 'PL-SPCK-17', icon: 'mdi-account-arrow-right-outline', solo: true, showtip: false, active: true },
              { code: 'PL-SSRC-3', icon: 'mdi-car', solo: false, showtip: false, active: true }
            ]

            defaultPlans.forEach(plan => {
              planMap.set(plan.code, {
                icon: plan.icon,
                solo: plan.solo,
                showtip: plan.showtip,
                tip: plan.tip || '',
                active: true
              })
            })
          }
        } else {
          // Add
          if (item.planCode === inclusivePlanCode) {
            console.log('add inclusive plan')
            // Replace selected plans with the inclusive plan
            this.selectedPlans = [[], [], [], [], []]
            this.selectedPlans[0].push(item)
          } else {
            // Add the plan to the selected plans
            this.clickedField(`item: ${item.planCode} added to cart`, -1)
            selectedPlans.push(item)
          }
        }
      },
      planSelected (item, index) {
        if (!this.selectedPlans[index]) {
          return false
        }
        if (!this.selectedPlans[index].includes(item)) {
          return false
        // its not in the cart
        }
        return true
      // its in our cart
      },
      formatCoverageHeader (coverage) {
        let headingsections = ''
        // headingsections = coverage.planName.split('-')
        return coverage.genericName // headingsections[0] + headingsections[1] + headingsections[2]
      },
      selectCoverage (coverage) {
        this.disabled = false
        this.plan = coverage
      },
      submit () {
        if (this.selectedPlans !== '') {
          this.$store
            .dispatch('setTravelCoverageDetails', this.coverageDetails)
            .then(() => {
              this.$router.push({ path: '/travelpersonaldetailsca' })
            })
        }
      }
    }
  }
</script>

<style scoped>
.closeDialogBtn {
  position: absolute;
  right: 0px;
  top: 6px;
  margin-right: 0px;
  min-width: unset !important;
  width: 35px;
}
.v-card__title {
  word-break: unset;
}
</style>
